@font-face {
  font-family: 'fpicons';
  src:url('fonts/fpicons.eot?yg5dv7');
  src:url('fonts/fpicons.eot?#iefixyg5dv7') format('embedded-opentype'),
    url('fonts/fpicons.woff?yg5dv7') format('woff'),
    url('fonts/fpicons.ttf?yg5dv7') format('truetype'),
    url('fonts/fpicons.svg?yg5dv7#fpicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fp-i-"], [class*=" fp-i-"] {
  font-family: 'fpicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flowplayer{position:relative;width:100%;counter-increment:flowplayer;background-size:contain;background-repeat:no-repeat;background-position:center center;display:inline-block;}
.flowplayer *{font-weight:inherit;font-family:inherit;font-style:inherit;text-decoration:inherit;font-size:100%;padding:0;border:0;margin:0;list-style-type:none}
.flowplayer a:focus{outline:0}
.flowplayer video{width:100%}
.flowplayer.is-ipad video{-webkit-transform:translateX(-2048px);}
.is-ready.flowplayer.is-ipad video{-webkit-transform:translateX(0)}
.flowplayer .fp-player{position:absolute;top:0;left:0;width:100%;height:100%}
.flowplayer .fp-engine,.flowplayer .fp-ui,.flowplayer .fp-message{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;z-index:1}
.flowplayer .fp-ui{z-index:11}
.flowplayer .fp-message{display:none;text-align:center;padding-top:5%;cursor:default;}
.flowplayer .fp-message h2{font-size:120%;margin-bottom:1em}
.flowplayer .fp-message p{color:#666;font-size:95%}
.flowplayer .fp-title{line-height:30px;font-weight:normal;font-family:'myriad pro',Helvetica,Arial,sans-serif;font-size:11px;cursor:default;color:#fff;width:auto;max-width:50%;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;float:left;padding:0 20px;}
.is-rtl.flowplayer .fp-title{float:right}
.aside-time.flowplayer .fp-title{display:none !important}
.flowplayer .fp-controls{position:absolute;bottom:0;width:100%;}
.no-background.flowplayer .fp-controls{background-color:transparent !important;background-image:-webkit-gradient(linear,left top,left bottom,from(transparent),to(transparent)) !important;background-image:-webkit-linear-gradient(top,transparent,transparent) !important;background-image:-moz-linear-gradient(top,transparent,transparent) !important;background-image:-o-linear-gradient(top,transparent,transparent) !important;background-image:linear-gradient(to bottom,transparent,transparent) !important}
.is-fullscreen.flowplayer .fp-controls{bottom:3px}
.is-mouseover.flowplayer .fp-controls{bottom:0}
.flowplayer .fp-controls,.flowplayer .fp-title,.flowplayer .fp-fullscreen,.flowplayer .fp-unload,.flowplayer .fp-close,.flowplayer .fp-embed,.flowplayer.aside-time .fp-time{background-color:#000;background-color:rgba(0,0,0,0.65);}
.no-background.flowplayer .fp-controls,.no-background.flowplayer .fp-title,.no-background.flowplayer .fp-fullscreen,.no-background.flowplayer .fp-unload,.no-background.flowplayer .fp-close,.no-background.flowplayer .fp-embed,.no-background.flowplayer.aside-time .fp-time{background-color:transparent !important;background-image:-webkit-gradient(linear,left top,left bottom,from(transparent),to(transparent)) !important;background-image:-webkit-linear-gradient(top,transparent,transparent) !important;background-image:-moz-linear-gradient(top,transparent,transparent) !important;background-image:-o-linear-gradient(top,transparent,transparent) !important;background-image:linear-gradient(to bottom,transparent,transparent) !important;text-shadow:0 0 1px #000}
.no-background.flowplayer .fp-play,.no-background.flowplayer .fp-brand{background-color:transparent !important;background-image:-webkit-gradient(linear,left top,left bottom,from(transparent),to(transparent)) !important;background-image:-webkit-linear-gradient(top,transparent,transparent) !important;background-image:-moz-linear-gradient(top,transparent,transparent) !important;background-image:-o-linear-gradient(top,transparent,transparent) !important;background-image:linear-gradient(to bottom,transparent,transparent) !important;text-shadow:0 0 1px #000}
.flowplayer.fixed-controls .fp-controls{background-color:#000}
.flowplayer .fp-timeline{background-color:#a5a5a5}
.flowplayer .fp-buffer{background-color:#eee}
.flowplayer .fp-progress{background-color:#00a7c8}
.flowplayer .fp-volumeslider{background-color:#a5a5a5}
.flowplayer .fp-volumelevel{background-color:#00a7c8}
.flowplayer .fp-waiting{display:none;margin:19% auto;text-align:center;}
.flowplayer .fp-waiting *{-webkit-box-shadow:0 0 5px #333;-moz-box-shadow:0 0 5px #333;box-shadow:0 0 5px #333}
.flowplayer .fp-waiting em{width:1em;height:1em;-webkit-border-radius:1em;-moz-border-radius:1em;border-radius:1em;background-color:rgba(255,255,255,0.8);display:inline-block;-webkit-animation:pulse .6s infinite;-moz-animation:pulse .6s infinite;animation:pulse .6s infinite;margin:.3em;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);}
.flowplayer .fp-waiting em:nth-child(1){-webkit-animation-delay:.3s;-moz-animation-delay:.3s;animation-delay:.3s}
.flowplayer .fp-waiting em:nth-child(2){-webkit-animation-delay:.45s;-moz-animation-delay:.45s;animation-delay:.45s}
.flowplayer .fp-waiting em:nth-child(3){-webkit-animation-delay:.6s;-moz-animation-delay:.6s;animation-delay:.6s}
.flowplayer .fp-waiting p{color:#ccc;font-weight:bold}
.flowplayer .fp-speed{font-size:30px;background-color:#333;background-color:rgba(51,51,51,0.8);color:#eee;margin:0 auto;text-align:center;width:120px;padding:.1em 0 0;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .5s;-moz-transition:opacity .5s;transition:opacity .5s;}
.flowplayer .fp-speed.fp-hilite{opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer .fp-help{position:absolute;top:0;left:-9999em;z-index:100;background-color:#333;background-color:rgba(51,51,51,0.9);width:100%;height:100%;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .2s;-moz-transition:opacity .2s;transition:opacity .2s;text-align:center;}
.is-help.flowplayer .fp-help{left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer .fp-help .fp-help-section{margin:3%;direction:ltr}
.flowplayer .fp-help .fp-help-basics{margin-top:6%}
.flowplayer .fp-help p{color:#eee;margin:.5em 0;font-size:14px;line-height:1.5;display:inline-block;margin:1% 2%}
.flowplayer .fp-help em{background:#eee;-webkit-border-radius:.3em;-moz-border-radius:.3em;border-radius:.3em;margin-right:.4em;padding:.3em .6em;color:#333}
.flowplayer .fp-help small{font-size:90%;color:#aaa}
.flowplayer .fp-help .fp-close{display:block}
@media (max-width: 600px){.flowplayer .fp-help p{font-size:9px}
}.flowplayer .fp-dropdown{position:absolute;top:5px;width:100px;background-color:#000 !important;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;box-sizing:border-box;-moz-box-sizing:border-box;margin:0 !important;list-style-type:none !important;}
.flowplayer .fp-dropdown:before{content:'';display:block;position:absolute;top:-5px;left:calc(50% - 5px);width:0;height:0;border-left:5px solid transparent;border-right:5px solid transparent;border-bottom:5px solid rgba(51,51,51,0.9)}
.flowplayer .fp-dropdown li{padding:10px !important;margin:0 !important;color:#fff !important;font-size:11px !important;list-style-type:none !important;}
.flowplayer .fp-dropdown li.active{background-color:#00a7c8 !important;cursor:default !important}
.flowplayer .fp-dropdown.fp-dropup{bottom:20px;top:auto;}
.flowplayer .fp-dropdown.fp-dropup:before{top:auto;bottom:-5px;border-bottom:none;border-top:5px solid rgba(51,51,51,0.9)}
.flowplayer .fp-tooltip{background-color:#000;color:#fff;display:none;position:absolute;padding:5px;}
.flowplayer .fp-tooltip:before{content:'';display:block;position:absolute;bottom:-5px;width:0;height:0;left:calc(50% - 5px);border-left:5px solid transparent;border-right:5px solid transparent;border-top:5px solid #000}
.flowplayer .fp-timeline-tooltip{bottom:35px}
.flowplayer .fp-timeline:hover+.fp-timeline-tooltip{display:block}
.flowplayer .fp-subtitle{position:absolute;bottom:40px;left:-99999em;z-index:10;text-align:center;width:100%;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .3s;-moz-transition:opacity .3s;transition:opacity .3s;}
.flowplayer .fp-subtitle p{display:inline;background-color:#333;background-color:rgba(51,51,51,0.9);color:#eee;padding:.1em .4em;font-size:16px;line-height:1.6;}
.flowplayer .fp-subtitle p:after{content:'';clear:both}
.flowplayer .fp-subtitle p b{font-weight:bold}
.flowplayer .fp-subtitle p i{font-style:italic}
.flowplayer .fp-subtitle p u{text-decoration:underline}
.flowplayer .fp-subtitle.fp-active{left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer .fp-fullscreen,.flowplayer .fp-unload,.flowplayer .fp-mute,.flowplayer .fp-embed,.flowplayer .fp-close,.flowplayer .fp-play,.flowplayer .fp-menu{font-family:'fpicons' !important;color:#fff !important;font-size:15px !important;text-align:center !important;line-height:30px !important;text-decoration:none !important;}
.is-rtl.flowplayer .fp-fullscreen,.is-rtl.flowplayer .fp-unload,.is-rtl.flowplayer .fp-mute,.is-rtl.flowplayer .fp-embed,.is-rtl.flowplayer .fp-close,.is-rtl.flowplayer .fp-play,.is-rtl.flowplayer .fp-menu{-webkit-transform:scale(-1,1);-moz-transform:scale(-1,1);transform:scale(-1,1)}
.is-rtl.flowplayer .fp-menu{-webkit-transform:none;-moz-transform:none;transform:none}
.flowplayer .fp-fullscreen:before{content:"\e602"}
.flowplayer .fp-unload:before,.flowplayer .fp-close:before{content:"\e600"}
.flowplayer .fp-mute:before{content:"\e606"}
.flowplayer .fp-embed:before{content:"\e603"}
.flowplayer .fp-play:before{content:"\e608"}
.flowplayer .fp-menu:before{content:"\e604"}
.flowplayer .fp-flash-disabled{background:#333;width:390px;margin:0 auto;position:absolute;bottom:0;color:#fff}
.is-splash.flowplayer .fp-ui,.is-paused.flowplayer .fp-ui{background:url(img/play_white.png) center no-repeat;background-size:11%;}
.is-rtl.is-splash.flowplayer .fp-ui,.is-rtl.is-paused.flowplayer .fp-ui{background:url(img/play_white_rtl.png) center no-repeat;background-size:11%}
@media (-webkit-min-device-pixel-ratio: 2){.is-splash.flowplayer .fp-ui,.is-paused.flowplayer .fp-ui{background:url(img/play_white@x2.png) center no-repeat;background-size:11%}
.is-rtl.is-splash.flowplayer .fp-ui,.is-rtl.is-paused.flowplayer .fp-ui{background:url(img/play_white_rtl@x2.png) center no-repeat;background-size:11%}
}.is-fullscreen.flowplayer .fp-ui{background-size:auto}
.is-seeking.flowplayer .fp-ui,.is-loading.flowplayer .fp-ui{background-image:none}
.flowplayer .fp-brand{color:#fff !important;position:absolute;right:115px;font-weight:normal !important;font-family:'myriad pro',Helvetica,Arial,sans-serif !important;text-decoration:none !important;line-height:15px !important;font-size:11px !important;height:15px;width:55px;bottom:9px;box-sizing:border-box;text-align:center;padding:1px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}
.has-menu.flowplayer .fp-brand{right:152px}
.is-rtl.flowplayer .fp-brand{right:auto;left:125px}
.has-menu.is-rtl.flowplayer .fp-brand{left:152px}
.no-brand.flowplayer .fp-brand{display:none}
.no-volume.no-mute.flowplayer .fp-brand{right:10px;}
.has-menu.no-volume.no-mute.flowplayer .fp-brand{right:47px}
.no-volume.flowplayer .fp-brand{right:50px}
.no-mute.flowplayer .fp-brand{right:95px}
.flowplayer .fp-logo{position:absolute;top:auto;left:15px;bottom:40px;cursor:pointer;display:none;z-index:100;}
.flowplayer .fp-logo img{width:100%}
.is-embedded.flowplayer .fp-logo{display:block}
.fixed-controls.flowplayer .fp-logo{bottom:15px}
.flowplayer .fp-fullscreen,.flowplayer .fp-unload,.flowplayer .fp-close{position:absolute;top:10px;left:auto;right:10px;display:block;width:30px;height:23px;text-align:center;cursor:pointer;height:30px;width:30px;}
.is-rtl.flowplayer .fp-fullscreen,.is-rtl.flowplayer .fp-unload,.is-rtl.flowplayer .fp-close{right:auto;left:10px}
.flowplayer .fp-unload,.flowplayer .fp-close{display:none}
.flowplayer .fp-play{display:none;height:30px !important;position:absolute;bottom:0;left:0;text-align:center;}
.is-rtl.flowplayer .fp-play{left:auto;right:0}
.is-playing.flowplayer .fp-play:before{content:"\e607"}
.flowplayer .fp-menu{display:none;position:absolute;bottom:0;z-index:11;right:10px;}
.is-rtl.flowplayer .fp-menu{right:auto;left:10px}
.has-menu.flowplayer .fp-menu{display:block}
.flowplayer .fp-menu .fp-dropdown{z-index:12;display:none;left:-42.5px;line-height:auto;width:149px;-webkit-transform:none;-moz-transform:none;transform:none;}
.is-rtl.flowplayer .fp-menu .fp-dropdown{left:-10px}
.flowplayer .fp-menu.dropdown-open .fp-dropdown{display:block}
.flowplayer.is-ready.is-closeable .fp-unload{display:block}
.flowplayer.is-ready.is-closeable .fp-embed{right:90px}
.flowplayer.is-ready.is-closeable .fp-fullscreen{right:50px}
.flowplayer.is-fullscreen .fp-fullscreen{display:block !important;}
.flowplayer.is-fullscreen .fp-fullscreen:before{content:"\e601"}
.flowplayer .fp-timeline{height:3px;position:relative;overflow:hidden;top:10px;height:10px;margin:0 225px 0 55px;}
.no-brand.flowplayer .fp-timeline{margin-right:160px;}
.has-menu.no-brand.flowplayer .fp-timeline{margin-right:187px}
.no-volume.no-brand.flowplayer .fp-timeline{margin-right:95px}
.no-mute.no-brand.flowplayer .fp-timeline{margin-right:130px}
.no-mute.no-volume.no-brand.flowplayer .fp-timeline{margin-right:55px}
.has-menu.flowplayer .fp-timeline{margin-right:252px}
.no-volume.flowplayer .fp-timeline{margin-right:160px}
.no-mute.flowplayer .fp-timeline{margin-right:195px}
.no-mute.no-volume.flowplayer .fp-timeline{margin-right:120px;}
.has-menu.no-mute.no-volume.flowplayer .fp-timeline{margin-right:157px}
.is-rtl.flowplayer .fp-timeline{margin:0 55px 0 225px;}
.no-brand.is-rtl.flowplayer .fp-timeline{margin-left:160px;}
.has-menu.no-brand.is-rtl.flowplayer .fp-timeline{margin-left:197px}
.has-menu.is-rtl.flowplayer .fp-timeline{margin-left:262px}
.no-volume.is-rtl.flowplayer .fp-timeline{margin-left:95px}
.no-mute.is-rtl.flowplayer .fp-timeline{margin-left:130px}
.no-mute.no-volume.is-rtl.flowplayer .fp-timeline{margin-left:55px}
.is-long.flowplayer .fp-timeline{margin:0 255px 0 85px;}
.no-volume.is-long.flowplayer .fp-timeline{margin-right:180px}
.no-mute.is-long.flowplayer .fp-timeline{margin-right:140px}
.has-menu.is-long.flowplayer .fp-timeline{margin-right:292px}
.no-brand.is-long.flowplayer .fp-timeline{margin-right:190px;}
.no-volume.no-brand.is-long.flowplayer .fp-timeline{margin-right:125px}
.no-mute.no-brand.is-long.flowplayer .fp-timeline{margin-right:85px}
.has-menu.no-brand.is-long.flowplayer .fp-timeline{margin-right:227px}
.is-rtl.is-long.flowplayer .fp-timeline{margin:85px 0 190px 0;}
.no-volume.is-rtl.is-long.flowplayer .fp-timeline{margin-left:125px}
.no-mute.is-rtl.is-long.flowplayer .fp-timeline{margin-left:85px}
.aside-time.flowplayer .fp-timeline,.no-time.flowplayer .fp-timeline{margin:0 190px 0 10px;}
.has-menu.aside-time.flowplayer .fp-timeline,.has-menu.no-time.flowplayer .fp-timeline{margin-right:227px}
.aside-time.no-brand.flowplayer .fp-timeline{margin-right:115px}
.aside-time.no-volume.flowplayer .fp-timeline,.no-time.no-volume.flowplayer .fp-timeline{margin-right:115px}
.aside-time.no-mute.flowplayer .fp-timeline,.no-time.no-mute.flowplayer .fp-timeline{margin-right:75px}
.is-rtl.aside-time.flowplayer .fp-timeline,.is-rtl.no-time.flowplayer .fp-timeline{margin:0 10px 0 115px}
.is-rtl.aside-time.no-volume.flowplayer .fp-timeline,.is-rtl.no-time.no-volume.flowplayer .fp-timeline{margin-left:50px}
.is-rtl.aside-time.no-mute.flowplayer .fp-timeline,.is-rtl.no-time.no-mute.flowplayer .fp-timeline{margin-left:10px}
.flowplayer .fp-buffer,.flowplayer .fp-progress{position:absolute;top:0;left:auto;height:100%;cursor:col-resize}
.flowplayer .fp-buffer{-webkit-transition:width .25s linear;-moz-transition:width .25s linear;transition:width .25s linear}
.flowplayer .fp-timeline.no-animation .fp-buffer{-webkit-transition:none;-moz-transition:none;transition:none}
.flowplayer .fp-progress.animated{transition-timing-function:linear;transition-property:width,height}
.flowplayer.is-touch .fp-timeline{overflow:visible}
.flowplayer.is-touch .fp-progress{-webkit-transition:width .2s linear;-moz-transition:width .2s linear;transition:width .2s linear;box-sizing:border-box}
.flowplayer.is-touch .fp-timeline.is-dragging .fp-progress{-webkit-transition:right .1s linear,border .1s linear,top .1s linear,left .1s linear;-moz-transition:right .1s linear,border .1s linear,top .1s linear,left .1s linear;transition:right .1s linear,border .1s linear,top .1s linear,left .1s linear}
.flowplayer.is-touch.is-mouseover .fp-progress:after,.flowplayer.is-touch.is-mouseover .fp-progress:before{content:'';box-sizing:border-box;display:block;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;position:absolute;right:-5px}
.flowplayer.is-touch.is-rtl.is-mouseover .fp-progress:after,.flowplayer.is-touch.is-rtl.is-mouseover .fp-progress:before{right:auto;left:-5px}
.flowplayer.is-touch.is-rtl.is-mouseover .fp-progress:after{left:-10px;-webkit-box-shadow:-1px 0 4px rgba(0,0,0,0.5);-moz-box-shadow:-1px 0 4px rgba(0,0,0,0.5);box-shadow:-1px 0 4px rgba(0,0,0,0.5)}
.flowplayer.is-touch.is-mouseover .fp-progress:before{width:10px;height:10px}
.flowplayer.is-touch.is-mouseover .fp-progress:after{height:18px;width:18px;top:-4px;right:-10px;border:5px solid rgba(255,255,255,0.65);-webkit-box-shadow:1px 0 4px rgba(0,0,0,0.5);-moz-box-shadow:1px 0 4px rgba(0,0,0,0.5);box-shadow:1px 0 4px rgba(0,0,0,0.5)}
.flowplayer.is-touch.is-mouseover .fp-timeline.is-dragging .fp-progress:after{border:10px solid #fff;-webkit-border-radius:20px;-moz-border-radius:20px;border-radius:20px;-webkit-transition:inherit;-moz-transition:inherit;transition:inherit;top:-5px;right:-10px}
.flowplayer.is-touch.is-rtl.is-mouseover .fp-timeline.is-dragging .fp-progress:after{left:-15px;right:auto;border:10px solid #fff}
.flowplayer .fp-volume{position:absolute;top:12px;right:10px;}
.has-menu.flowplayer .fp-volume{right:37px}
.is-rtl.flowplayer .fp-volume{right:auto;left:10px}
.is-rtl.has-menu.flowplayer .fp-volume{left:37px}
.flowplayer .fp-mute{position:relative;width:30px;height:30px;float:left;top:-12px;cursor:pointer;}
.is-rtl.flowplayer .fp-mute{float:right}
.no-mute.flowplayer .fp-mute{display:none}
.flowplayer .fp-volumeslider{width:75px;height:6px;cursor:col-resize;float:left;}
.is-rtl.flowplayer .fp-volumeslider{float:right}
.no-volume.flowplayer .fp-volumeslider{display:none}
.flowplayer .fp-volumelevel{height:100%}
.flowplayer .fp-time{text-shadow:0 0 1px #000;font-size:11px;font-weight:normal;font-family:'myriad pro',Helvetica,Arial,sans-serif !important;color:#fff;width:100%;}
.flowplayer .fp-time.is-inverted .fp-duration{display:none}
.flowplayer .fp-time.is-inverted .fp-remaining{display:inline}
.flowplayer .fp-time em{width:35px;height:10px;line-height:10px;text-align:center;position:absolute;bottom:9px}
.no-time.flowplayer .fp-time{display:none}
.is-long.flowplayer .fp-time em{width:65px}
.flowplayer .fp-elapsed{left:10px;}
.is-rtl.flowplayer .fp-elapsed{left:auto;right:10px}
.flowplayer .fp-remaining,.flowplayer .fp-duration{right:180px;color:#eee;}
.no-brand.flowplayer .fp-remaining,.no-brand.flowplayer .fp-duration{right:125px;}
.has-menu.no-brand.flowplayer .fp-remaining,.has-menu.no-brand.flowplayer .fp-duration{right:152px}
.no-volume.no-brand.flowplayer .fp-remaining,.no-volume.no-brand.flowplayer .fp-duration{right:50px}
.no-mute.no-brand.flowplayer .fp-remaining,.no-mute.no-brand.flowplayer .fp-duration{right:95px}
.no-mute.no-volume.no-brand.flowplayer .fp-remaining,.no-mute.no-volume.no-brand.flowplayer .fp-duration{right:10px}
.has-menu.flowplayer .fp-remaining,.has-menu.flowplayer .fp-duration{right:217px}
.no-volume.flowplayer .fp-remaining,.no-volume.flowplayer .fp-duration{right:115px}
.no-mute.flowplayer .fp-remaining,.no-mute.flowplayer .fp-duration{right:160px}
.no-mute.no-volume.flowplayer .fp-remaining,.no-mute.no-volume.flowplayer .fp-duration{right:75px;}
.has-menu.no-mute.no-volume.flowplayer .fp-remaining,.has-menu.no-mute.no-volume.flowplayer .fp-duration{right:112px}
.is-rtl.flowplayer .fp-remaining,.is-rtl.flowplayer .fp-duration{right:auto;left:180px;}
.no-brand.is-rtl.flowplayer .fp-remaining,.no-brand.is-rtl.flowplayer .fp-duration{left:115px;}
.has-menu.no-brand.is-rtl.flowplayer .fp-remaining,.has-menu.no-brand.is-rtl.flowplayer .fp-duration{left:142px}
.has-menu.is-rtl.flowplayer .fp-remaining,.has-menu.is-rtl.flowplayer .fp-duration{left:207px}
.no-volume.is-rtl.flowplayer .fp-remaining,.no-volume.is-rtl.flowplayer .fp-duration{left:50px}
.no-mute.is-rtl.flowplayer .fp-remaining,.no-mute.is-rtl.flowplayer .fp-duration{left:95px}
.no-mute.no-volume.is-rtl.flowplayer .fp-remaining,.no-mute.no-volume.is-rtl.flowplayer .fp-duration{left:10px}
.flowplayer .fp-remaining{display:none}
.flowplayer.aside-time .fp-time{position:absolute;top:10px;left:10px;bottom:auto !important;width:auto;background-color:#000;background-color:rgba(0,0,0,0.65);height:30px;padding:0 5px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;line-height:30px;text-align:center;font-size:15px;}
.no-background.flowplayer.aside-time .fp-time{background-color:transparent !important}
.flowplayer.aside-time .fp-time strong,.flowplayer.aside-time .fp-time em{position:static}
.flowplayer.aside-time .fp-time .fp-elapsed::after{content:' / '}
.flowplayer.is-splash,.flowplayer.is-poster{cursor:pointer;}
.flowplayer.is-splash .fp-controls,.flowplayer.is-poster .fp-controls,.flowplayer.is-splash .fp-fullscreen,.flowplayer.is-poster .fp-fullscreen,.flowplayer.is-splash .fp-unload,.flowplayer.is-poster .fp-unload,.flowplayer.is-splash .fp-time,.flowplayer.is-poster .fp-time,.flowplayer.is-splash .fp-embed,.flowplayer.is-poster .fp-embed,.flowplayer.is-splash .fp-title,.flowplayer.is-poster .fp-title,.flowplayer.is-splash .fp-brand,.flowplayer.is-poster .fp-brand{display:none !important}
.flowplayer.is-poster .fp-engine{top:-9999em}
.flowplayer.is-loading .fp-waiting{display:block}
.flowplayer.is-loading .fp-controls,.flowplayer.is-loading .fp-time{display:none}
.flowplayer.is-loading .fp-ui{background-position:-9999em}
.flowplayer.is-loading video.fp-engine{position:absolute;top:-9999em}
.flowplayer.is-seeking .fp-waiting{display:block}
.flowplayer.is-playing{background-image:none !important;background-color:#333;}
.flowplayer.is-playing.hls-fix.is-finished .fp-engine{position:absolute;top:-9999em}
.flowplayer.is-fullscreen{top:0 !important;left:0 !important;border:0 !important;margin:0 !important;width:100% !important;height:100% !important;max-width:100% !important;z-index:99999 !important;-webkit-box-shadow:0 !important;-moz-box-shadow:0 !important;box-shadow:0 !important;background-image:none !important;background-color:#333;}
.is-rtl.flowplayer.is-fullscreen{left:auto !important;right:0 !important}
.flowplayer.is-fullscreen .fp-player{background-color:#333}
.flowplayer.is-error{border:1px solid #909090;background:#fdfdfd !important;}
.flowplayer.is-error h2{font-weight:bold;font-size:large;margin-top:10%}
.flowplayer.is-error .fp-message{display:block}
.flowplayer.is-error object,.flowplayer.is-error video,.flowplayer.is-error .fp-controls,.flowplayer.is-error .fp-time,.flowplayer.is-error .fp-subtitle{display:none}
.flowplayer.is-ready.is-muted .fp-mute{opacity:.7;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=70)}
.flowplayer.is-ready.is-muted .fp-mute:before{content:"\e605"}
.flowplayer.is-mouseout .fp-controls,.flowplayer.is-mouseout .fp-title{height:0;-webkit-transition:height .15s .3s;-moz-transition:height .15s .3s;transition:height .15s .3s}
.is-fullscreen.flowplayer.is-mouseout .fp-controls{height:3px;bottom:0}
.flowplayer.is-mouseout .fp-title{overflow:hidden}
.flowplayer.is-mouseout .fp-timeline{margin:0 !important}
.flowplayer.is-mouseout .fp-timeline{-webkit-transition:height .15s .3s,top .15s .3s,margin .15s .3s;-moz-transition:height .15s .3s,top .15s .3s,margin .15s .3s;transition:height .15s .3s,top .15s .3s,margin .15s .3s;height:4px;top:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0}
.flowplayer.is-mouseout .fp-fullscreen,.flowplayer.is-mouseout .fp-unload,.flowplayer.is-mouseout .fp-elapsed,.flowplayer.is-mouseout .fp-remaining,.flowplayer.is-mouseout .fp-duration,.flowplayer.is-mouseout .fp-embed,.flowplayer.is-mouseout .fp-volume,.flowplayer.is-mouseout .fp-play,.flowplayer.is-mouseout .fp-menu,.flowplayer.is-mouseout .fp-brand,.flowplayer.is-mouseout .fp-timeline-tooltip,.flowplayer.is-mouseout.aside-time .fp-time{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .15s .3s;-moz-transition:opacity .15s .3s;transition:opacity .15s .3s}
.flowplayer.is-mouseover .fp-controls,.flowplayer.fixed-controls .fp-controls{height:30px}
.flowplayer.is-mouseover .fp-title,.flowplayer.fixed-controls .fp-title{height:30px}
.flowplayer.is-mouseover .fp-fullscreen,.flowplayer.fixed-controls .fp-fullscreen,.flowplayer.is-mouseover .fp-unload,.flowplayer.fixed-controls .fp-unload,.flowplayer.is-mouseover .fp-elapsed,.flowplayer.fixed-controls .fp-elapsed,.flowplayer.is-mouseover .fp-remaining,.flowplayer.fixed-controls .fp-remaining,.flowplayer.is-mouseover .fp-duration,.flowplayer.fixed-controls .fp-duration,.flowplayer.is-mouseover .fp-embed,.flowplayer.fixed-controls .fp-embed,.flowplayer.is-mouseover .fp-logo,.flowplayer.fixed-controls .fp-logo,.flowplayer.is-mouseover .fp-volume,.flowplayer.fixed-controls .fp-volume,.flowplayer.is-mouseover .fp-play,.flowplayer.fixed-controls .fp-play,.flowplayer.is-mouseover .fp-menu,.flowplayer.fixed-controls .fp-menu{opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer.fixed-controls .fp-volume{display:block}
.flowplayer.fixed-controls .fp-controls{bottom:-30px;}
.is-fullscreen.flowplayer.fixed-controls .fp-controls{bottom:0}
.flowplayer.fixed-controls .fp-time em{bottom:-20px;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);}
.is-fullscreen.flowplayer.fixed-controls .fp-time em{bottom:10px}
.flowplayer.is-disabled .fp-progress{background-color:#999}
.flowplayer.is-flash-disabled{background-color:#333;}
.flowplayer.is-flash-disabled object.fp-engine{z-index:100}
.flowplayer.is-flash-disabled .fp-flash-disabled{display:block;z-index:101}
.flowplayer .fp-embed{position:absolute;top:10px;left:auto;right:50px;display:block;width:30px;height:30px;text-align:center;}
.is-rtl.flowplayer .fp-embed{right:auto;left:50px}
.flowplayer .fp-embed-code{position:absolute;display:none;top:10px;right:67px;background-color:#333;padding:3px 5px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;-webkit-box-shadow:0 0 3px #ccc;-moz-box-shadow:0 0 3px #ccc;box-shadow:0 0 3px #ccc;font-size:12px;}
.is-closeable.flowplayer .fp-embed-code{right:99px}
.flowplayer .fp-embed-code:before{content:'';width:0;height:0;position:absolute;top:2px;right:-10px;border:5px solid transparent;border-left-color:#333}
.is-rtl.flowplayer .fp-embed-code{right:auto;left:67px;}
.is-rtl.flowplayer .fp-embed-code:before{right:auto;left:-10px;border-left-color:transparent;border-right-color:#333}
.flowplayer .fp-embed-code textarea{width:400px;height:16px;font-family:monaco,"courier new",verdana;color:#777;white-space:nowrap;resize:none;overflow:hidden;border:0;outline:0;background-color:transparent;color:#ccc}
.flowplayer .fp-embed-code label{display:block;color:#999}
.flowplayer.is-embedding .fp-embed,.flowplayer.is-embedding .fp-embed-code{display:block;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer.no-time .fp-embed{left:10px !important;}
.is-rtl.flowplayer.no-time .fp-embed{left:auto;right:10px !important}
.flowplayer.is-live .fp-timeline,.flowplayer.is-live .fp-duration,.flowplayer.is-live .fp-remaining{display:none}
.flowplayer .fp-context-menu{position:absolute;display:none;z-index:1001;background-color:#fff;padding:10px;border:1px solid #aaa;-webkit-box-shadow:0 0 4px #888;-moz-box-shadow:0 0 4px #888;box-shadow:0 0 4px #888;width:170px;}
.flowplayer .fp-context-menu li{text-align:center !important;padding:10px;color:#444 !important;font-size:11px !important;margin:0 -10px 0 -10px;}
.flowplayer .fp-context-menu li a{color:#00a7c8 !important;font-size:12.100000000000001px !important}
.flowplayer .fp-context-menu li:hover:not(.copyright){background-color:#eee}
.flowplayer .fp-context-menu li.copyright{margin:0;padding-left:110px;background-image:url("img/flowplayer.png");background-repeat:no-repeat;background-size:100px 20px;background-position:5px 5px;border-bottom:1px solid #bbb;}
@media (-webkit-min-device-pixel-ratio: 2){.flowplayer .fp-context-menu li.copyright{background-image:url("img/flowplayer@2x.png")}
}@-moz-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@-webkit-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@-o-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@-ms-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@keyframes pulse{0%{opacity:0}
100%{opacity:1}
}.flowplayer.is-touch.is-mouseover .fp-progress:before{background-color:#00a7c8}
.flowplayer .fp-title{position:absolute;top:10px;left:10px;}
.is-rtl.flowplayer .fp-title{left:auto;right:10px}
.flowplayer .fp-title,.flowplayer .fp-unload,.flowplayer .fp-fullscreen,.flowplayer .fp-embed{-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px}
.flowplayer .fp-embed-code{right:85px;}
.is-closeable.flowplayer .fp-embed-code{right:125px}
.is-rtl.flowplayer .fp-embed-code{right:auto;left:85px}
.flowplayer.is-mouseout .fp-menu{display:none}
.flowplayer.is-mouseout .fp-controls{-webkit-transition:none;-moz-transition:none;transition:none;-webkit-animation:functional-controls-hide 1s 1;-moz-animation:functional-controls-hide 1s 1;animation:functional-controls-hide 1s 1}
.flowplayer.is-mouseout .fp-timeline{-webkit-transition:none;-moz-transition:none;transition:none}
.flowplayer.is-mouseout .fp-volume,.flowplayer.is-mouseout .fp-brand,.flowplayer.is-mouseout .fp-play,.flowplayer.is-mouseout .fp-time{-webkit-transition:none;-moz-transition:none;transition:none;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
.flowplayer.fixed-controls .fp-elapsed,.flowplayer.fixed-controls.is-mouseover .fp-elapsed{left:50px}
.flowplayer.fixed-controls .fp-controls,.flowplayer.fixed-controls.is-mouseover .fp-controls{bottom:-30px;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;left:0;right:0;}
.is-fullscreen.flowplayer.fixed-controls .fp-controls,.is-fullscreen.flowplayer.fixed-controls.is-mouseover .fp-controls{bottom:0}
.flowplayer.fixed-controls .fp-controls .fp-play,.flowplayer.fixed-controls.is-mouseover .fp-controls .fp-play{left:10px}
.flowplayer.fixed-controls .fp-controls .fp-timeline,.flowplayer.fixed-controls.is-mouseover .fp-controls .fp-timeline{margin-left:95px;}
.is-long.flowplayer.fixed-controls .fp-controls .fp-timeline,.is-long.flowplayer.fixed-controls.is-mouseover .fp-controls .fp-timeline{margin-left:125px}
.flowplayer.is-mouseover .fp-controls{bottom:10px;left:50px;right:10px;width:auto;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;}
.is-rtl.flowplayer.is-mouseover .fp-controls{left:10px;right:50px}
.flowplayer.is-mouseover .fp-controls .fp-play{left:-40px;display:block;background-color:#000;background-color:rgba(0,0,0,0.65);width:30px;height:30px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;}
.is-rtl.flowplayer.is-mouseover .fp-controls .fp-play{left:auto;right:-40px}
.flowplayer.is-mouseover .fp-controls .fp-timeline{-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;}
.flowplayer.is-mouseover .fp-controls .fp-timeline .fp-progress,.flowplayer.is-mouseover .fp-controls .fp-timeline .fp-buffer{-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px}
.flowplayer.is-mouseover .fp-controls .fp-menu .fp-dropdown{bottom:35px;left:auto;right:-10px;}
.is-rtl.flowplayer.is-mouseover .fp-controls .fp-menu .fp-dropdown{right:auto;left:-10px}
.flowplayer.is-mouseover .fp-controls .fp-menu .fp-dropdown:before{display:none}
.flowplayer.is-mouseover .fp-controls li{border-color:#000;}
.flowplayer.is-mouseover .fp-controls li.active{border-color:#00a7c8}
.flowplayer.is-mouseover .fp-controls li:last-child:before{content:'';display:block;position:absolute;bottom:-5px;width:0;height:0;right:10px;border-bottom:none;border-top-width:5px;border-top-style:solid;border-top-color:inherit;border-left:5px solid transparent;border-right:5px solid transparent;}
.is-rtl.flowplayer.is-mouseover .fp-controls li:last-child:before{right:auto;left:10px}
.flowplayer .fp-elapsed,.flowplayer.play-button .fp-elapsed{left:60px;}
.is-rtl.flowplayer .fp-elapsed,.is-rtl.flowplayer.play-button .fp-elapsed{right:60px;left:auto}
.flowplayer .fp-time em{bottom:19px}
@-moz-keyframes functional-controls-hide{0%{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
100%{bottom:0;right:0;left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
}@-webkit-keyframes functional-controls-hide{0%{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
100%{bottom:0;right:0;left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
}@-o-keyframes functional-controls-hide{0%{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
100%{bottom:0;right:0;left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
}@-ms-keyframes functional-controls-hide{0%{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
100%{bottom:0;right:0;left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
}@keyframes functional-controls-hide{0%{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)}
100%{bottom:0;right:0;left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
}